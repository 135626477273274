<template>
    <div class="storeCustomer">
        <el-tabs v-model="activeName" type="card" class="education-content tabs-full">
            <el-tab-pane label="客服管理" name="serviceAdminName">
                <serviceAdmin />
            </el-tab-pane>
            <el-tab-pane label="客服商品管理" name="serviceGoodsAdminName">
                <commodityManagement />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import serviceAdmin from './../../components/admin/shopServiceAdmin/serviceAdmin.vue';
    import commodityManagement from './../../components/admin/shopServiceAdmin/commodityManagement.vue';
    export default {
        name: "storeCustomer",
        components: {
            serviceAdmin,
            commodityManagement
        },
        data(){
            return {
                activeName:'serviceAdminName'
            }
        },
        created() {
            // console.log(24,this.$route)
            if(this.$route.query.activeName){
                this.activeName = this.$route.query.activeName
            }
        },
    }
</script>

<style scoped lang="scss">

</style>